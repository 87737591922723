.wrap {
    width: 100%;
    position: relative;
    text-align: center;
}

.link {
    text-decoration: none;
    color: #434343;
}

.content {
    height: 120px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    user-select: none;
}

.wrap-menu {
    width: 94%;
    margin-left: 3%;
    position: absolute;
    top: 36%;
    padding-top: 50px;
    background-image: url("./../img/menu.png");
    background-size: 100% 100%;
    border-radius: 10px;
}

.title {
    position: absolute;
    width: 100%;
    letter-spacing: 10px;
    top: 4px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 20px;
}

.menu {
    max-height: 46vh;
    min-height: 40vh;
    padding-top: 20px;
    overflow: scroll;
}

.menu-item {
    height: 110px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    user-select: none;
}

.menu-item-icon {
    text-align: center;
}

.menu-item-icon-img {
    width: 42px;
    margin: 0 auto;
}

.menu-item-text {
    margin-left: 16px;
    display: block;
}
