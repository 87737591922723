/* AppointmentRules.css */
.appointment-rules-container {
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 90vh; /* 设置一个适合移动端显示的最大高度 */
    overflow-y: auto; /* 当内容超出容器高度时显示垂直滚动条 */
}

.rule-section {
    margin-bottom: 16px;
}

.rule-section h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.rule-section p, .rule-section ol {
    font-size: 16px;
    line-height: 1.5;
}

.rule-section ol li {
    margin-bottom: 8px;
}
