.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.body {
    flex: 1;
    display: flex;
}

.bottom {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
    margin-bottom: 16px;
}
