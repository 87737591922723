/* SelectRecordList.css */
.select-record-list {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Reduced gap */
    padding: 8px; /* Reduced padding */
}

.card-item {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
}

.card-content {
    padding: 8px; /* Reduced padding */
}

.card-content p {
    margin: 2px 0; /* Reduced margin */
}

.button {
    margin-top: 4px; /* Reduced margin */
}
