/* AppointmentPanel.css (紧凑版) */
.panel {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    margin: 10px; /* 减少了外边距 */
    padding: 8px; /* 减少了内边距 */
}

.panel-title {
    font-size: 16px; /* 减小了字体大小 */
    font-weight: bold;
    margin-bottom: 8px; /* 减少了标题与其他元素之间的间距 */
}

.panel-item {
    margin-bottom: 4px; /* 减少了项目之间的间距 */
}

.panel-item label {
    font-weight: bold;
}

.panel-item span {
    /* 这里可以根据需要调整，如果需要更紧凑，可以考虑减小字体大小或行高 */
}
.panel-item--action {
    text-align: right; /* 将按钮对齐到右侧 */
    margin-top: 10px; /* 根据需要添加上边距，使按钮与上面的信息项分开 */
}

/* 如果你希望按钮有独立的样式，可以进一步定义 */
.panel-item--action .ant-btn {
    /* 这里可以添加针对Ant Design Button组件的样式 */
}
