.page-content {
  width: 95%;
  margin: 16px auto;
}

.service-item {
  padding: 5px;

  .service-card {
    background: linear-gradient(45deg, #f0f0f0 25%, #ffffff 25%),
    linear-gradient(-45deg, #f0f0f0 25%, #ffffff 25%);
    background-size: 6px 6px;
    background-blend-mode: overlay;
    filter: contrast(1.2) brightness(1.1);
    border-radius: 16px;
    padding: 16px 16px 8px 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
    }
  }

  .icon-wrapper {
    width: 56px;
    height: 56px;
    border-radius: 14px;
    margin: 0 auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 100%);
    }

    svg {
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
      z-index: 1;
    }
  }

  .service-name {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #333;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.5px;
  }
}
